import React from "react"
import { Box } from "grommet"

const Home = ({ list, wrap }) => {
  return (
    <>
      <Box wrap={wrap} direction="row" gap="xxsmall">
        {list}
      </Box>
    </>
  )
}

export default Home
