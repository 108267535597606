import React from "react"
import { Box, Text } from "grommet"

const Home = ({ onClick, buttons, activeIndex }) => {
  return (
    <>
      <Box direction="row-responsive">
        {buttons &&
          buttons.map((e, i) => (
            <Box
              key={i}
              onClick={() => onClick && onClick(e, i)}
              align="center"
              color="white"
              background={activeIndex === i ? "brand" : "black"}
              fill="horizontal"
              pad="small"
            >
              <Text color="white"> {e.label}</Text>
            </Box>
          ))}
      </Box>
    </>
  )
}

export default Home
