import React from "react"
import Layout from "../../../containers/layout"
import Calendar from "../../../containers/Bazi"

const Home = () => {
  return (
    <Layout>
      <Calendar />
    </Layout>
  )
}

export default Home
