import React, { useState, useEffect } from "react"
import { Accordion, AccordionPanel } from "grommet"
import DateTimePicker from "../../components/DateTimePicker"
import Pillar from "../../components/Pillar"
import BaziPillar from "./BaziPillar"
import BoxButton from "../../components/BoxButton"

import { dateObjectToBazi } from "../../cm-lib/FourPillar"
import { useTranslation } from "react-i18next"
import { parseStringDate, getQueryString, chunk } from "../../helpers"
import { format } from "date-fns"

const isBrowser = typeof window !== `undefined`

const Home = ({ inputDate, onBazi, hideOtherInformation }) => {
  const { t } = useTranslation()
  const qs = getQueryString()
  const [dateObject] = useState(parseStringDate(qs.dt, new Date()))
  const [pillars, setPillars] = useState(null)
  const [gender, setGender] = useState(parseInt(qs.g) || 0)
  const [bazi] = useState(
    dateObjectToBazi(dateObject, gender === 0 ? "M" : "F")
  )
  useEffect(() => {
    const bz = [
      {
        header: "Hour",
        ganzhi: bazi.GanZhiHour.ganzhi,
      },
      {
        header: "Day",
        ganzhi: bazi.GanZhiDay.ganzhi,
      },
      {
        header: "Month",
        ganzhi: bazi.GanZhiMonth.ganzhi,
      },
      {
        header: "Year",
        ganzhi: bazi.GanZhiYear.ganzhi,
      },
    ].map(e => <BaziPillar hiddenStems header={e.header} ganzhi={e.ganzhi} />)

    const luckHeader = bazi.tenYearCycle.map((e, i) => {
      return <BaziPillar header={bazi.startWesternAge + i * 10} ganzhi={e} />
    })

    setPillars({
      bz,
      hundredYear: chunk(
        bazi.hundredYear.map(e => (
          <BaziPillar header={e.year} ganzhi={e.pillar} />
        )),
        10
      ),
      luckHeader,
      bazi,
    })
  }, [bazi])
  return (
    <>
      <BoxButton
        activeIndex={gender}
        onClick={(e, i) => setGender(i)}
        buttons={[
          {
            label: "M",
          },
          {
            label: "F",
          },
        ]}
      />
      <br />
      <DateTimePicker
        dateObject={dateObject}
        onClick={date => {
          const dt = format(date, "yyyy-MM-dd HH:mm")
          isBrowser && window.location.assign(`?dt=${dt}&g=${gender}`)
        }}
      />
      <br />

      {pillars && <Pillar list={pillars.bz} />}

      {!hideOtherInformation && (
        <Accordion>
          <AccordionPanel label="More Information">
            {Object.keys(bazi.stars.classical).map(k => (
              <AccordionPanel
                label={`${k} ${t(k)}: ${bazi.stars.classical[k]}`}
              ></AccordionPanel>
            ))}
          </AccordionPanel>
        </Accordion>
      )}
      <br />
      {!hideOtherInformation && pillars && <Pillar list={pillars.luckHeader} />}

      <br />
      {!hideOtherInformation &&
        pillars &&
        pillars.hundredYear.map(e => <Pillar list={e} />)}
    </>
  )
}

export default Home
