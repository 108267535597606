import React from "react"
import { Box, Text } from "grommet"
import { getColor } from "../../cm-lib/QiMen"
import { HIDDEN_STEMS, EB } from "../../cm-lib/constants"
import { useTranslation } from "react-i18next"

const StandardBox = ({ children }) => (
  <Box justify="center" align="center" alignContent="center">
    {children}
  </Box>
)

const PillarGrid = ({ children, t, background }) => (
  <Box
    background={background || getColor(t)}
    pad="small"
    fill="horizontal"
    justify="center"
    align="center"
    alignContent="center"
  >
    {" "}
    {children}{" "}
  </Box>
)

const Home = ({ header, ganzhi, custom, plumNumber, hiddenStems }) => {
  const { t } = useTranslation()
  return (
    <>
      <Box direction="column" fill={"horizontal"} gap="xxsmall">
        <PillarGrid background="light-2">
          <StandardBox> {header}</StandardBox>
        </PillarGrid>
        <PillarGrid t={ganzhi.substr(0, 1)}>
          <StandardBox> {ganzhi.substr(0, 1)}</StandardBox>
          <StandardBox>
            {" "}
            <Text size="12px" textAlign="center">
              {t(ganzhi.substr(0, 1))}
            </Text>
          </StandardBox>
        </PillarGrid>
        <PillarGrid t={ganzhi.substr(1, 1)}>
          <StandardBox> {ganzhi.substr(1, 1)}</StandardBox>
          <StandardBox>
            {" "}
            <Text size="12px" textAlign="center">
              {t(ganzhi.substr(1, 1))}
            </Text>
          </StandardBox>
        </PillarGrid>
        {hiddenStems && (
          <PillarGrid t={ganzhi.substr(1, 1)}>
            <StandardBox> {HIDDEN_STEMS[ganzhi.substr(1, 1)]}</StandardBox>
          </PillarGrid>
        )}
        {custom && (
          <PillarGrid t={ganzhi.substr(1, 1)}>
            <StandardBox> {custom}</StandardBox>
          </PillarGrid>
        )}
      </Box>
    </>
  )
}

export default Home
